import {Booking} from "../components/form/model/Booking";
import {apiRequest} from "../components/Globals";
import BookingService from "./BookingService";
import {Fitting} from "../components/form/model/Fitting";
import {notify} from "../components/Notify";
import ConfigService from "./ConfigService";
import {jsPDF} from "jspdf";
import autoTable from 'jspdf-autotable'
import moment from "moment";
import {FIT_TYPE_NUM, FITTING_PRINT_GROUPS} from "../config/Globals";

export default class FittingService extends BookingService {
    url: string = '/bs/fitting';

    searchParams = {
        search: null,
        dateFrom: null,
        dateTo: null
    };
    pdfDate = {
        dateFrom: null,
        dateTo: null
    }

    list: Fitting[] = [];

    loadList() {
        this.clearSearchTimeOut();

        if (!this.pagination.hasMore) {
            return;
        }

        let payload = {
            searchParams: this.searchParams,
            pagination: this.pagination,
        };


        apiRequest(this.url, payload)
            .then(result => {
                if (result) {
                    this.pagination.hasMore = result.custom.length >= this.pagination.pageSize;

                    result.custom.forEach((row) => {

                        let booking = new Booking();
                        booking.assign(row);
                        booking.id = row.booking_id;

                        let fitting = new Fitting();
                        fitting.assign(row);

                        fitting.id = row.id;
                        fitting.userId = row.fitter_id;
                        fitting.booking = booking;
                        fitting.timeFrom = row.fit_time;
                        fitting.timeTo = row.fit_time_end;

                        this.list.push(fitting);
                    })
                }

                if (this.onDataChange) {
                    this.onDataChange();
                }
            });

    }

    generateDispatchPdf(fittings: [], endRender: Function) {
        const doc = new jsPDF();

        const tableHead = [
            ['Customer Name', 'Order No.', 'Qty', 'Product', 'Fitter', 'Notes', '']
        ]
        const tables = [];

        fittings.forEach(fitting => {
                const fitProducts = fitting.product_list;

                if (fitProducts) {
                    fitProducts.forEach(product => {
                        if (parseInt(product.type_id) !== FIT_TYPE_NUM.fitting) {
                            return
                        }

                        const findExistingTable = tables.find(table => table.group === FITTING_PRINT_GROUPS[product.name]);

                        if (findExistingTable) {
                            tables.some(table => {
                                if (table.group === FITTING_PRINT_GROUPS[product.name]) {

                                    table.data.push([
                                        fitting.company ? fitting.company : fitting.full_name,
                                        fitting.customer_no,
                                        product.quantity,
                                        product.name,
                                        fitting.fitter_name,
                                        '', ''
                                    ]);
                                    return true;
                                }
                                return false;
                            })
                        } else if (FITTING_PRINT_GROUPS[product.name]) {
                            tables.push({
                                    group: FITTING_PRINT_GROUPS[product.name],
                                    title: `${FITTING_PRINT_GROUPS[product.name]} — ${moment(fitting.fit_date).format('DD MMMM YYYY')}`,
                                    data: [
                                        tableHead[0],
                                        [fitting.company ? fitting.company : fitting.full_name,
                                            fitting.customer_no,
                                            product.quantity,
                                            product.name,
                                            fitting.fitter_name,
                                            '', '']
                                    ],
                                    style: {
                                        startY: 20,
                                        headStyles: {
                                            fillColor: [255, 255, 255], textColor: [0, 0, 0],
                                            lineColor: [204, 204, 204],
                                            lineWidth: 0.1,
                                        },
                                        columnStyles: {
                                            0: {cellWidth: 33},
                                            1: {cellWidth: 30},
                                            2: {cellWidth: 10},
                                            3: {cellWidth: 40},
                                            4: {cellWidth: 35},
                                            5: {cellWidth: 25},
                                            6: {cellWidth: 15}
                                        }
                                    }
                                }
                            )
                        }
                    })
                }
            }
        )

        tables.forEach((table, index) => {
            if (index !== 0) {
                doc.addPage();
            }

            doc.text(10, 10, table.title);
            if (index === 0) {
                doc.setFontSize(10);
                doc.text(155, 10, `(date printed: ${moment(new Date()).format('DD MMMM YYYY')})`);
            }
            autoTable(doc, {
                head: tableHead,
                body: table.data.slice(1),
                theme: 'grid',
                ...table.style
            })
        });

        doc.save(`fittings_${this.pdfDate.dateFrom}-${this.pdfDate.dateTo}.pdf`);

        if (endRender) {
            endRender();
        }
    }

    downloadDispatchPdf(dates, endRender: Function) {
        Object.assign(this.pdfDate, dates);

        let payload = {
            searchParams: this.pdfDate,
            pagination: {
                pageSize: 100000,
                page: 0,
                hasMore: true
            }
        };

        apiRequest(this.url, payload)
            .then(data => {
                if (!data || !data.custom) {
                    notify({text: "No fittings for chosen dates"});
                    if (endRender) {
                        endRender();
                    }
                    return;
                }

                this.generateDispatchPdf(data.custom, endRender);

            }).catch(error => {
            console.error(error);
            if (endRender) {
                endRender();
            }
        });
    }

    downloadCsv(params: {
                    search: string,
                    status: number[],
                    repId: number,
                    filteredItems: [],
                    dateFrom: string,
                    dateTo: string
                } = {}, endRender: Function
    ) {
        Object.assign(this.searchParams, params);

        let payload = {
            searchParams: this.searchParams,
            pagination: this.pagination
        };

        apiRequest('/bs/fitting/csv', payload)
            .then(result => {
                if (!result?.custom?.fileName) {
                    notify({text: 'Download fail'})

                    return;
                }

                let configService = new ConfigService();
                let apiUrl = configService.data.apiUrl;
                let link = document.createElement('a');

                link.href = apiUrl + result?.custom?.fileName;
                link.setAttribute(
                    'download',
                    result?.custom?.fileName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

                if (endRender) {
                    endRender();
                }

            });
    }

}