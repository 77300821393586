import FittingService from "./FittingService";
import {Fitting} from "../components/form/model/Fitting";
import {apiRequest} from "../components/Globals";
import {Booking} from "../components/form/model/Booking";

export default class FittingTodayRunService extends FittingService {
    url: string = '/bs/fitting/today-run';

    list: Fitting|Booking[] = [];

    loadList() {
        this.clearSearchTimeOut();

        if (!this.pagination.hasMore) {
            return;
        }

        let payload = {
            searchParams: this.searchParams,
            pagination: this.pagination
        };


        apiRequest(this.url, payload)
            .then(result => {
                if (result) {
                    this.pagination.hasMore = result.custom.length >= this.pagination.pageSize;

                    result.custom.forEach((row) => {

                        if (row.fit_time) {
                            let booking = new Booking();
                            booking.assign(row);
                            booking.id = row.booking_id;

                            let fitting = new Fitting();
                            fitting.assign(row);

                            fitting.id = row.id;
                            fitting.userId = row.fitter_id;
                            fitting.booking = booking;

                            this.list.push(fitting);
                        } else {
                            let booking = new Booking();
                            booking.assign(row);
                            this.list.push(booking);
                        }
                    })
                }

                if (this.onDataChange) {
                    this.onDataChange();
                }
            });

    }

}