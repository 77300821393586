import React from 'react';
import {Auth} from "../components/Auth";
import * as moment from "moment";
import {Booking} from "../components/form/model/Booking";
import {notify} from "../components/Notify";
import {apiRequest} from "../components/Globals";
import {BOOKING_STATUS} from "../config/Globals";
import Select from "../components/form/Select";
import TextArea from "../components/form/TextArea";
import FittingTodayRunService from "../service/FittingTodayRunService";
import {Fitting} from "../components/form/model/Fitting";

export default class FittingTodaysRun extends React.Component {
    state = {
        selectedIndex: -1,
        commentTypeError: null,
        commentMessageError: null,
        logs: []
    };

    auth: Auth = new Auth();
    fittingService: FittingTodayRunService = new FittingTodayRunService(() => {
        this.checkAfterLoad()
    });

    canStart: boolean = false;
    nextClicked: boolean = false;
    currentItemIndex: number = null;

    constructor(props) {
        super(props);

        this.refCommentType = React.createRef();
        this.refCommentMessage = React.createRef();

        this.onStart = this.onStart.bind(this);
        this.loadBookings = this.loadBookings.bind(this);
        this.checkAfterLoad = this.checkAfterLoad.bind(this);
        this.getNextItem = this.getNextItem.bind(this);
        this.onAddComment = this.onAddComment.bind(this);
        this.loadComments = this.loadComments.bind(this);

        this.auth.load();
    }

    componentDidMount(): void {
        this.loadBookings();
    }

    checkAfterLoad() {
        let selectedIndex = this.state.selectedIndex;

        if (this.nextClicked && this.fittingService.list.length >= this.state.selectedIndex + 2) {
            this.nextClicked = false;
            selectedIndex++;
        }

        this.currentItemIndex = null;
        this.canStart = false;

        this.fittingService.list.some((item, i) => {
            if (!item.customerNotified && i === 0) {
                this.canStart = true;

                return true;
            }

            if (item.customerNotified && !item.isDone) {
                this.currentItemIndex = i;
                selectedIndex = i;

                return true;
            }

            return false;
        });

        this.setState({selectedIndex: selectedIndex});
    }

    loadBookings() {
        this.fittingService.setSearchParams({
            repId: this.auth.userId,
            status: [BOOKING_STATUS.active],
            dateFrom: moment(new Date()).format('YYYY-MM-DD'),
            dateTo: moment(new Date()).format('YYYY-MM-DD'),
        })
    }

    onStart() {
        const item = this.getItem();
        const nextItem = this.getNextItem();
        this.nextClicked = true;

        apiRequest('/bs/fitting/notify-customer', {
            prevFittingId: item instanceof Fitting ? item.id : null,
            prevBookingId: item instanceof Booking ? item.id : null,
            nextFittingId: nextItem instanceof Fitting ? nextItem.id : null,
            nextBookingId: nextItem instanceof Booking ? nextItem.id : null,
        }).then(() => {
            notify({
                text: 'SMS was sent to the customer that you are on your way.'
            });
            this.loadBookings();
        })
            .catch(response => {
                notify({text: response.message});
            });
    }

    getNextItem(): Booking|Fitting {
        if (this.fittingService.list.length < this.state.selectedIndex + 2) {
            return null;
        }

        // if (this.bookingService.list[this.state.selectedIndex + 1].customerNotified) {
        //     return null;
        // }

        return this.fittingService.list[this.state.selectedIndex + 1];
    }

    getItem(): Booking|Fitting {
        console.log(['bbb', this.state.selectedIndex]);

        if (this.state.selectedIndex < 0 || this.state.selectedIndex >= this.fittingService.list.length) {
            return null;
        }

        return this.fittingService.list[this.state.selectedIndex];
    }

    loadComments(id: number) {
        if (!id) {
            this.setState({logs: []});
            return;
        }

        apiRequest('/bs/fitting/comment-list', {
            'fittingId': id
        })
            .then(response => {
                this.setState({logs: response && response.custom ? response.custom : []})
            });

    }

    onAddComment() {
        if (!this.refCommentType.current.value) {
            this.setState({commentTypeError: 'Type is required.'});
            return;
        }

        if (this.refCommentType.current.value === '2' && !this.refCommentMessage.current.value) {
            this.setState({commentMessageError: 'Message is required'});
            return;
        }


        apiRequest('/bs/fitting/comment-add', {
            'fittingId': this.getItem().id,
            'type': this.refCommentType.current.value,
            'message': this.refCommentMessage.current.value,
        })
            .then(response => {
                notify({text: 'Log has been added.'});
                this.refCommentType.current.clear();
                this.refCommentType.current.forceUpdate();
                this.refCommentMessage.current.value = '';
                this.refCommentMessage.current.forceUpdate();

                this.loadComments(this.getItem().id);

            })
            .catch(response => {
                notify({text: response.message});
            });

        this.setState({
            commentTypeError: null,
            commentMessageError: null
        });
    }

    onSelectItem(i, id) {
        this.setState({selectedIndex: i});
        this.loadComments(id);
    }

    render() {
        return (
            <>
                <h1>Today's Run</h1>

                {this.fittingService && this.fittingService.list.length ?
                    <>
                        <div className="panel">

                            <div className="panel__top">
                                <div className="panel__title panel__title--todays-run">Today's Run</div>
                                <div className="action">
                                    <ul className="runs__nav">
                                        <li className={(this.state.selectedIndex < 0 ? ' current' : '')} onClick={() => this.onSelectItem(-1, null)}>Summary</li>
                                        {this.fittingService.list.map((item, i) => {
                                            let itemClass = '';

                                            if (item.isDone) {
                                                itemClass += ' done';
                                            }

                                            if (this.state.selectedIndex === i) {
                                                itemClass += ' current';
                                            }

                                            return (
                                                <li
                                                    // className={item.status === '3' ? 'done' : (item.status === '2' ? 'current' : '')}
                                                    className={itemClass}
                                                    key={i}
                                                    onClick={() => {
                                                        this.onSelectItem(i, item.id)
                                                    }}
                                                >{i + 1}</li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>

                            <div className="panel__body panel__body--no-padding">
                                <div className="runs">

                                    {this.getItem() ?
                                        <div className="runs__item">
                                            <h3>Customer's Info</h3>
                                            <ul className="customer__info">
                                                {this.getItem() instanceof Booking ?
                                                    <>
                                                        <li className="customer__name">{this.getItem().firstName + ' ' + this.getItem().lastName}</li>
                                                        <li className="customer__location">{this.getItem().unitNo ? this.getItem().unitNo + ', ' : ''}{this.getItem().address} {this.getItem().suburb}, {this.getItem().postalCode}</li>
                                                        <li className="customer__phone">{this.getItem().phone}</li>
                                                        {this.getItem().callBefore ?
                                                            <li className="customer__phone">Call Customer Before Appointment</li>
                                                            : ''}
                                                    </>
                                                    :
                                                    <>
                                                        <li className="customer__name">{this.getItem().booking.firstName + ' ' + this.getItem().booking.lastName}</li>
                                                        <li className="customer__location">{this.getItem().booking.address} {this.getItem().suburb}, {this.getItem().booking.postalCode}</li>
                                                        <li className="customer__phone">{this.getItem().booking.phone}</li>
                                                        {this.getItem().booking.callBefore ?
                                                            <li className="customer__phone">Call Customer Before Appointment</li>
                                                            : ''}
                                                    </>
                                                }
                                            </ul>
                                            <h3>{this.getItem() instanceof Booking ? 'M&Q Info' : 'Fitting Info'}</h3>
                                            <ul className="customer__info">
                                                {this.getItem() instanceof Booking ?
                                                    <>
                                                        <li className="customer__appointment">{moment(this.getItem().date + ' ' + this.getItem().timeFrom).format('h:mma')} to {moment(this.getItem().date + ' ' + this.getItem().timeTo).format('h:mma')} ({moment(this.getItem().date).format('DD MMMM YYYY')})</li>
                                                        {this.getItem().blinds > 0 ? <li className="customer__job">{this.getItem().blinds}x Indoor Blinds</li> : ''}
                                                        {this.getItem().indoorShutters > 0 ? <li className="customer__job">{this.getItem().indoorShutters}x Indoor Shutters</li> : ''}
                                                        {this.getItem().curtains > 0 ? <li className="customer__job">{this.getItem().curtains}x Curtains</li> : ''}
                                                        {this.getItem().outdoorBlinds > 0 ? <li className="customer__job">{this.getItem().outdoorBlinds}x Outdoor Blinds</li> : ''}
                                                        <li className="customer__notes">{this.getItem().message}</li>
                                                    </>
                                                    :
                                                    <>
                                                        <li className="customer__appointment">{moment(this.getItem().date + ' ' + this.getItem().timeFrom).format('h:mma')} to {moment(this.getItem().date + ' ' + this.getItem().timeTo).format('h:mma')} ({moment(this.getItem().date).format('DD MMMM YYYY')})</li>
                                                        <li className="customer__job">{this.getItem().customerNo}</li>
                                                        <li className="customer__job">{this.getItem().type}</li>
                                                        <li className="customer__notes">{this.getItem().windows}</li>
                                                        <li className="customer__notes">{this.getItem().notes}</li>
                                                        <li className="customer__notes">{this.getItem().balanceDue}</li>
                                                    </>
                                                }
                                            </ul>

                                            <div className="form__row form__row--add-logs">
                                                <div className="form__label">Note</div>
                                                <div className="form__fields">
                                                    <Select
                                                        label="Type"
                                                        size="small"
                                                        ref={this.refCommentType}
                                                        options={[
                                                            {
                                                                value: '1',
                                                                label: 'Customer not on-site'
                                                            },
                                                            {
                                                                value: '2',
                                                                label: 'Other'
                                                            },

                                                        ]}
                                                        errorMessage={this.state.commentTypeError}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form__row form__row--add-logs">
                                                <div className="form__label"></div>
                                                <div className="form__fields"><TextArea
                                                    label="Message"
                                                    size="small"
                                                    ref={this.refCommentMessage}
                                                    errorMessage={this.state.commentMessageError}
                                                /></div>
                                            </div>

                                            <div className="form__row form__row--add-logs">
                                                <div className="form__label"></div>
                                                <div className="form__fields">
                                                    <button className="btn btn--grey btn--xs-1" onClick={this.onAddComment}>Add Notes</button>
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        <div className="runs__item">
                                            <div className="runs__summary">
                                                {this.fittingService.list.map((item, i) => {
                                                    return (
                                                        <div className="runs__summary__item" key={i} onClick={() => {
                                                            this.onSelectItem(i, item.id)
                                                        }}>
                                                            {item instanceof Booking ?
                                                              <>
                                                                  <div className="runs__summary__time">{moment(item.date + ' ' + item.timeFrom).format('h:mma')}</div>
                                                                  <div className="runs__summary__location">{item.firstName} {item.lastName} {item.callBefore ? <span className="call__before__appointment">Call</span>  : ''} <a href={'tel:' + item.phone}>{item.phone}</a><br/>
                                                                      {item.unitNo ? item.unitNo + ', ' : ''}{item.address}, {item.suburb} </div>

                                                              </>
                                                                :
                                                                <>
                                                                    <div className="runs__summary__time">{moment(item.date + ' ' + item.timeFrom).format('h:mma')}</div>
                                                                    <div className="runs__summary__location">{item.booking.firstName} {item.booking.lastName} {item.booking.callBefore ?
                                                                        <span className="call__before__appointment">Call</span> : ''} <a href={'tel:' + item.booking.phone}>{item.booking.phone}</a><br/>
                                                                        {item.booking.unitNo ? item.booking.unitNo + ', ' : ''}{item.booking.address}, {item.booking.suburb} </div>
                                                                </>

                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>

                                    }
                                </div>
                            </div>
                            {
                                (this.fittingService.list.length - 1 > this.state.selectedIndex)
                                && ((this.state.selectedIndex === this.currentItemIndex) || (this.state.selectedIndex === -1 && this.canStart)) ?
                                    <div className="panel__bottom">
                                        <div className="bh">
                                            <div className="btn btn--xs-1" onClick={this.onStart}>{this.state.selectedIndex >= 0 ? 'Done & notify next customer' : 'Start & notify customer'}</div>
                                        </div>

                                    </div>
                                    : ''
                            }

                        </div>
                        {this.state.logs.length > 0 ?
                            <div className="panel">
                                <div className="panel__top">
                                    <div className="panel__title">Logs</div>
                                </div>
                                <div className="panel__body">

                                    {this.state.logs.map(item => {
                                        let shortcat = '';

                                        if (item.full_name) {
                                            const shortcatParts = item.full_name.split(' ');
                                            shortcat += shortcatParts[0].substr(0, 1).toUpperCase();

                                            if (shortcatParts[1]) {
                                                shortcat += shortcatParts[1].substr(0, 1).toUpperCase();
                                            }
                                        }

                                        return (
                                            <div className="logs" key={Math.random()}>
                                                <div className="logs__column">
                                                    <div className="logs__avatar">{shortcat}</div>
                                                </div>
                                                <div className="logs__column">
                                                    <div className="logs__info">
                                                        <span className="logs__info__name">{item.full_name}</span>
                                                        <span className="logs__info__time">{moment(item.event_date).format('DD MMM YYYY h:mma')}</span>
                                                    </div>
                                                    <div className="logs__details">{item.caption} | {item.message}</div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                            : ''}
                    </>
                    :

                    <div className="panel">

                        <div className="panel__top">
                            <div className="panel__title">Today's Run</div>
                        </div>

                        <div className="panel__body">
                            You do not have any runs for today.
                        </div>


                    </div>
                }
            </>
        );
    }
}