import {Auth} from "./Auth";
import {ROLES} from "../config/Globals";

export default class Access {
    static permissions = {
        Users: {
            view: [
                ROLES.manager
            ],
            loginAs: [

            ]
        },
        Calendar: {
            view: [
                ROLES.manager,
                ROLES.service,
                ROLES.commercial,
                ROLES.showroom
            ],
            typeSwitchConsultant: [
                ROLES.showroom,
                ROLES.commercial
            ],
            typeSwitchFitter: [
                ROLES.commercial,
                ROLES.service,
                ROLES.showroom,
                ROLES.manager,
                ROLES.showroom
            ],
        },
        MeasureAndQuote: {
            view: [
                ROLES.reps,
                ROLES.manager,
                ROLES.showroom,
                ROLES.service,
                ROLES.commercial,
            ],
            fullList: [
                ROLES.manager,
                ROLES.admin,
                ROLES.showroom,
                ROLES.commercial,
            ],
            dottedLink: [
                ROLES.manager,
                ROLES.admin,
                ROLES.showroom
            ],
            availability: [
                ROLES.reps,
                ROLES.manager,
                ROLES.fitter,
                ROLES.service,
                ROLES.commercial,
                ROLES.showroom
            ],
            availabilityFull: [
                ROLES.manager,
                ROLES.showroom
            ],
        },
        TodaysRun: {
            view: [
                ROLES.reps
            ]
        },
        Fitter: {
            view: [
                ROLES.fitter,
                ROLES.service,
                ROLES.showroom,
                ROLES.commercial,
                ROLES.manager,
                ROLES.reps
            ],
            fullList: [
                ROLES.commercial,
                ROLES.service,
                ROLES.showroom,
                ROLES.manager,
                ROLES.reps
            ],
            reportDispatch: [
                ROLES.admin,
                ROLES.showroom
            ]
        },
        EventTab: {
            booking: [
                ROLES.showroom,
                ROLES.manager,
                ROLES.service,
                ROLES.commercial,
            ],
            fitting: [
                ROLES.service,
                ROLES.showroom,
                ROLES.manager,
                ROLES.commercial,
            ]
        }
    };

    static isAllowed(category: string, action: string): boolean {
        let auth = new Auth();
        auth.load();

        // allow all for admin
        if (auth.role === ROLES.admin) {
            return true;
        }

        return Access.permissions[category] && Access.permissions[category][action] && Access.permissions[category][action].includes(auth.role);
    }
}